<template>
  <div class="container">
    <!-- <header-back name='登录'></header-back> -->
    <div class="top44">
      <div class="tit">
        <h5>欢迎注册</h5>

      </div>

      <div class="cen">

        <van-field
          v-model="loginForm.mobile"
          type="tel"
          v-if="isModel"
          placeholder="请输入手机号码"
        />
        <van-field
          v-model="loginForm.code"
          v-if="isModel"
          center
          clearable
          maxlength="6"
          placeholder="请输入短信验证码"
        >
          <template #button>
            <span
              :style="{color: variables.textColor}"
              @click="getVerify()"
              v-html="count"
            >发送验证码</span>
          </template>
        </van-field>
        <van-field
          v-model="loginForm.account"
          type="tel"
          placeholder="请输入账号"
        />
        <van-field
          v-model="loginForm.paw"
          type="password"
          placeholder="请输入密码"
        />
        <van-field name="checkbox">
          <template #input>
            <van-checkbox
              v-model="checked"
              :checked-color="variables.textColor"
              icon-size="0.5rem"
              style="color: #8c8c8c"
            >同意</van-checkbox>
            <span
              :style="{color: variables.textColor}"
              @click="protocol"
            >《积分兑换协议》</span>
          </template>
        </van-field>
        <div style="margin: 16px">
          <van-button
            round
            block
            type="info"
            native-type="submit"
            :color="variables.textColor"
            @click="login()"
          >注册</van-button>
          <p style="font-size:16px;text-align: center;padding: 12px 0;">已有账号,<router-link
              class="login"
              tag="span"
              to="/login"
            >前往登录</router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderBack from "../../components/header-back";
import { Toast, Dialog } from "vant";
import variables from "@/styles/variables.scss";
export default {
  components: { HeaderBack },
  data() {
    return {
      loginForm: {
        mobile: "",
        code: "",
        account: "",
        paw: "",
      },
      isModel: true,
      timer: null,
      count: "发送验证码",
      checked: true,
    };
  },
  computed: {
    variables() {
      return variables;
    },
  },
  created() {},
  methods: {
    onSpan(val) {
      this.isModel = true;
      if (val === 1) {
        this.isModel = false;
      }
    },
    getVerify() {
      // 验证手机号
      if (!this.loginForm.mobile) {
        Toast("请输入手机号码");
        return;
      }
      const TIME_COUNT = 60; // 更改倒计时时间
      if (!this.timer) {
        // 发送验证码
        API.postSendSms(this.loginForm).then((response) => {
          Toast("发送成功");
        });
        this.count = TIME_COUNT;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            clearInterval(this.timer); // 清除定时器
            this.count = "重新获取";
            this.timer = null;
          }
        }, 1000);
      }
    },
    login() {
      if (!this.loginForm.mobile) {
        Toast("请输入手机号码");
        return;
      }
      if (!this.loginForm.code) {
        Toast("请输入验证码");
        return;
      }
      if (!this.checked) {
        Toast("请勾选同意");
        return;
      }
      this.$emit("login", this.loginForm);
    },
    protocol() {
      Dialog.alert({
        title: "移动积分商城商品兑换服务协议",
        messageAlign: "left",
        message: `
<p style="margin:0">在使用移动积分商城商品代兑换服务之前，请您(即用户)仔细阅读下述协议条款。一旦您点击确认本协议，即表示您已接受了以下所述的条款和条件，同意受本协议约束。如果您不同意接受全部的条款和条件，那么您将无法使用本协议约定的技术服务。</p>
<p style="margin:0">第一条服务说明</p>
<p style="margin:0">1.1通过桔子网络进行积分商品兑换时，可自由选择自行通过您自有的移动积分商城账户和密码登陆进行兑换，也可选择将您的商城账户和密码授权给桔子网络进行代兑换。</p>
<p style="margin:0">1.2为了更好地给您提供商品代兑换服务，一旦您选择桔子网络代兑换服务，您同意将您的移动积分商城账户和密码授权给桔子网络，允许桔子网络使用您的移动积分商城账户和密码登陆，并完成商品兑换及其其他相关操作。</p>
<p style="margin:0">1.3接受此协议，代表自愿委托使用移动积分商城进行商品兑换。</p>
<p style="margin:0">第二条各方权利和义务</p>
<p style="margin:0">2.1桔子网络提供的是移动积分商城商品代兑换服务，您接受本协议，意味着您同意我们使用您填写的收货地址进行代兑换，同时您必须遵守移动积分商城相关规定(https://jf.10086.cn)。</p>
<p style="margin:0">2.2用户了解并同意，桔子网络会根据您的授权使用您的移动积分商城账户及密码操作代兑换事宜，为了后续进一步向您提供服务，也会在系统中记录您的前述相关信息。但是对于您的隐私信息及相关交易信息，桔子网络会严格保护。</p>
<p style="margin:0">2.3您同意在每次使用本服务并登陆您的移动积分商城账户后将账户中的历史订单同步到桔子网络，用于您进一步的查询和操作</p>
<p style="margin:0">2.4桔子网络可根据国家法律法规变化及维护交易秩序、保护消费者权益需要，不时修改本协议。</p>
`,
      }).then(() => {
        // on close
      });
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../styles/variables.scss";
.container {
  height: 100%;
  background-color: #fff;
  padding: 0 15px;
}
.cen {
  .van-cell {
    line-height: 36px;
  }
  .login {
    color: $textColor;
  }
}
.tit {
  padding: 12px;
  & > span {
    padding-top: 6px;
    font-size: 16px;
    cursor: pointer;
  }
}
</style>
